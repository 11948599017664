@import '@/styles/_global';
.container{
 margin: 0px 0rem 0.7rem 0rem;
 font-size: 0.90rem;
}

.national{
  all: unset;
  border: 1px solid var(--P-Blue, #1a325d);
  background: var(--P-Blue, #1a325d);
  padding: 0.2rem 1.5rem 0.2rem 1.5rem;
  color: #fff;
  margin-right: 0.5rem;
  border-radius: 0.2rem;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
}

.interNational{
  all: unset;
  border: 1px solid var(--P-Blue, #1a325d);
  background: var(--P-Blue, #1a325d);
  padding: 0.2rem 1.5rem 0.2rem 1.5rem;
  color: #fff;
  border-radius: 0.2rem;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
}

.notSelectedButton{
  border: 1px solid var(--P-Blue, #1a325d);
  background: #fff;
  cursor: pointer;
  color: var(--P-Blue, #1a325d);
}

