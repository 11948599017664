@import '@/styles/_global';
.img {
  border-radius: toRem(12);
  background: #dddee3;
  width: 100%;
  object-fit: cover;
  margin-bottom: toRem(12);
  max-height: toRem(204);
}
.root {
  gap:0;
  flex-direction: column;
  input {
    font-variant: lining-nums;
  }
}
.Modal__RightContainerTitle {
  color: var(--P-Blue, #1a325d);
  font-family: var(--font-baskervville);
  font-size: toRem(24);
  font-style: normal;
  font-weight: 400;
  line-height: toRem(32);
  letter-spacing: toRem(0.72);
  text-transform: capitalize;
  margin-bottom: toRem(12);
}

.Modal__RightContainerDescription {
  color: var(--N-Grey-Dark, #686868);
  font-family: var(--font-raleway);
  font-size: toRem(14);
  font-style: normal;
  font-weight: 500;
  line-height: toRem(18);
  flex-shrink: 0;
  text-align: left;
  margin-bottom: toRem(16);
  margin-top: toRem(16);
}

.thankYou {
  margin-top: toRem(96);
}

@include desktop() {
  .root {
    gap: toRemDesktop(40);
    flex-direction: row;
    .customImage{
      display: none;
    }

    img {
      border-radius: toRemDesktop(20);
      max-height: 100%;
      height: 100%;
    }
  }

  .Modal__RightContainerTitle {
    color: var(--P-Blue, #1a325d);
    font-family: var(--font-baskervville);
    font-size: toRemDesktop(54);
    font-style: normal;
    font-weight: 400;
    line-height: toRemDesktop(55);
    /* 101.852% */
    letter-spacing: toRemDesktop(-1.62);
    text-transform: capitalize;
    max-width: toRemDesktop(554);
    margin-bottom: toRemDesktop(12);
  }

  .Modal__RightContainerDescription {
    color: var(--N-Grey-Dark, #686868);
    font-family: var(--font-raleway);
    font-size: toRemDesktop(18);
    font-style: normal;
    font-weight: 500;
    line-height: toRemDesktop(24);
    /* 133.333% */
    letter-spacing: toRemDesktop(0.36);
    flex-shrink: 0;
    text-align: left;
    margin-bottom: toRemDesktop(32);
    max-width: toRemDesktop(554);
  }

  .formSection{
    margin-top: toRemDesktop(100)
  }

  .thankYou {
    margin-top: 0;
  }
}
@include mobile{
  .customImage{
    width: toRem(370);
    border-radius: toRem(16);
    height: toRem(144);
    display: block;
    padding: 0;
  }
  .Modal__RightContainerTitle{
    display: none;
  }
}