@import '@/styles/_global';
.root {
  & > img {
    border-radius: toRem(12);
    background: #dddee3;
    height: toRem(204);
    width: 100%;
    object-position: top;
    object-fit: cover;
    min-height: 300px;
  }
}
.header {
  font-size: toRem(24);
  line-height: toRem(32.1);
  letter-spacing: toRem(-0.72);
}

.text {
  font-size: toRem(14);
  line-height: toRem(18);
  letter-spacing: toRem(0.28);
}
.message {
  position: absolute;
  inset: 0;
  background: white;
  display: grid;
  place-items: center center;
  border-radius: toRemDesktop(20);
}
.icon {
  width: toRemDesktop(136);
  height: toRemDesktop(136);
  margin-bottom: toRemDesktop(54);
  display: block;
}
.mtitle {
  color: var(--P-Blue, #1a325d);
  text-align: center;
  font-size: toRemDesktop(64);
  font-style: normal;
  font-weight: 400;
  line-height: toRemDesktop(66); /* 103.125% */
  letter-spacing: toRemDesktop(-1.92);
  text-transform: capitalize;
  margin-bottom: toRemDesktop(24);
}
.mtext {
  color: var(--N-Grey-Dark, #686868);
  text-align: center;
  font-size: toRemDesktop(24);
  font-style: normal;
  font-weight: 500;
  line-height: toRemDesktop(32);
  letter-spacing: toRemDesktop(0.48);
}
.referBtn {
  display: flex;
  justify-content: center;
  padding-top: 20px;

  a {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 15px;
    height: 30px;
    padding: 0px 20px;
    color: #1A325D;
    transition: 0.5s;
    letter-spacing: 1px;
    overflow: hidden;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    font-weight: 600;
    text-decoration: none;
    border: 2px solid #1A325D;

    &:hover {
      background-color: #1A325D;
      color: #fff;

      svg {
        fill: #fff; 
      }
    }

    svg {
      animation: gift 2s infinite;
      transform-origin: center;
    }
  }
}

@keyframes gift{
  0%{transform: rotate(0deg) ;}
  10%{transform: rotate(-20deg) ;}
  20%{transform: rotate(20deg) ;}
  30%{transform: rotate(-20deg) ;}
  40%{transform: rotate(20deg) ;}
  50%, 100%{transform: rotate(0deg) ;}
}

@include desktop() {
  .root {
    display: flex;
    // width: toRemDesktop(504);
    height: toRemDesktop(746);
    column-gap: toRemDesktop(40);
    & > img {
      border-radius: toRemDesktop(20);
      background: #dddee3;
      width: toRemDesktop(504);
      height: 100%;
      object-position: bottom;
      object-fit: contain;
      margin-bottom: toRem(12);
    }
  }
  .content {
    flex: 1;
    height: 100%;
  }
  .header {
    font-size: toRemDesktop(54);
    line-height: toRemDesktop(55);
    letter-spacing: toRemDesktop(-1.62);
  }
  .text {
    font-size: toRemDesktop(18);
    line-height: toRemDesktop(24);
    letter-spacing: toRemDesktop(0.36);
  }
  .message {
    padding: toRemDesktop(250);
  }
}
