@import '@/styles/_global';
.text {
  color: var(--N-Grey-Dark, #686868);
  font-size: toRem(16);
}

.root {
  & > img {
    border-radius: toRem(12);
    background: #dddee3;
    // height: toRem(204);
    // width: toRem(333);
    // object-position: bottom;
    width: 100%;
    object-fit: cover;
    margin-bottom: toRem(12);
  }
}
.header {
  font-size: toRem(30);
  line-height: toRem(32.1);
  letter-spacing: toRem(-0.72);
}

.text {
  font-size: toRem(14);
  line-height: toRem(18);
  letter-spacing: toRem(0.28);
}
.message {
  position: absolute;
  inset: 0;
  background: white;
  display: grid;
  place-items: center center;
  border-radius: toRemDesktop(20);
}
.icon {
  width: toRemDesktop(136);
  height: toRemDesktop(136);
  margin-bottom: toRemDesktop(54);
  display: block;
}
.mtitle {
  color: var(--P-Blue, #1a325d);
  text-align: center;
  font-size: toRemDesktop(64);
  font-style: normal;
  font-weight: 400;
  line-height: toRemDesktop(66); /* 103.125% */
  letter-spacing: toRemDesktop(-1.92);
  text-transform: capitalize;
  margin-bottom: toRemDesktop(24);
}
.mtext {
  color: var(--N-Grey-Dark, #686868);
  text-align: center;
  font-size: toRemDesktop(24);
  font-style: normal;
  font-weight: 500;
  line-height: toRemDesktop(32);
  letter-spacing: toRemDesktop(0.48);
}
.downloadLink {
  color: var(--P-Blue, #1a325d);
  text-align: center;
  font-size: toRemDesktop(40);
  font-style: normal;
  font-weight: 400;
  line-height: toRemDesktop(40); /* 103.125% */
  letter-spacing: toRemDesktop(-1.92);
  text-transform: capitalize;
  margin-top: toRemDesktop(24);
  text-decoration: underline;
}
@include desktop() {
  .root {
    display: flex;
    // width: toRemDesktop(504);
    height: toRemDesktop(746);
    column-gap: toRemDesktop(40);
    & > img {
      border-radius: toRemDesktop(20);
      background: #dddee3;
      width: toRemDesktop(504);
      min-width: toRemDesktop(504);
      height: toRemDesktop(746);
      object-fit: contain;
      object-position: right;
    }
  }
  .content {
    flex: 1;
    height: 100%;
  }
  .header {
    font-size: toRemDesktop(54);
    line-height: toRemDesktop(55);
    letter-spacing: toRemDesktop(-1.62);
  }
  .text {
    font-size: toRemDesktop(16);
    line-height: toRemDesktop(24);
    letter-spacing: toRemDesktop(0.36);
  }
  .message {
    padding: toRemDesktop(250);
  }
}
