@import '@/styles/_global';
.root {
  padding: toRemDesktop(18);
  outline: none;
  flex: 1;
}

.error {
  position: absolute;
  top: 100%;
  left: 0;
  color: #dd0000;
  font-size: toRem(10);
}

@include desktop() {
  .error {
    font-size: toRemDesktop(12);
    margin-left: toRemDesktop(4);
  }
  .root {
    padding: toRemDesktop(14);
    outline: none;
    flex: 1;
  }

}
