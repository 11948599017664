@import '@/styles/_global';
.Input__FieldFont {
  border-radius: toRem(8);
  margin-top: 0.3rem
}

.selectDropdown{
  padding: toRem(12) toRem(26) toRem(12) toRem(12);
  min-width: toRem(60)
}

.SelectContainer {
  overflow: hidden;
  border-radius: toRem(8);
  margin-top: 0.3rem;
  position: relative;
  width: auto;

  font-family: var(--font-raleway);
  font-weight: 600;
  cursor: pointer;
}

.Checkbox {
  height: toRem(20);
  margin-top: toRem(8);
  width: toRem(20);
}

.btn {
  // py-[18px] px-6
  padding: 0.6rem 0.4rem;
  font-size: toRem(16);
  line-height: toRem(20);
  letter-spacing: toRem(1);
  border-radius: toRem(42);
}

.InputV2__root {
  margin-top: toRem(10);
  border-radius: toRem(8);
  position: relative;

  label {
    font-family: var(--font-raleway);
    font-size: toRem(14);
    font-weight: 600;
    line-height: toRem(16);
    letter-spacing: 1%;
    color: var(--primary-blue);
  }

  input {
    border: 1px solid #d4d4d4;
    width: 100%;
    border-radius: toRem(8);
    padding: toRem(8);
    margin-top: toRem(16);
    font-family: var(--font-raleway);
    font-size: toRem(14);
    font-weight: 500;
    line-height: toRem(19);
    letter-spacing: 2%;
    color: #686868;
    font-variant: lining-nums;
  }

  input:focus {
    outline: none;
  }
}
.error {
  position: absolute;
  top: 100%;
  left: 0;
  color: #dd0000;
  font-size: toRem(10);
  margin-top: 0;
}

@include desktop() {
  .Input__FieldFont {
    border-radius: toRemDesktop(20);
    margin-top: toRemDesktop(10);
  }

  .SelectContainer {
    border-radius: toRemDesktop(20);
    margin-top: toRemDesktop(10);
    width: 100%;
    padding: 0;
  }

  .SelectContainerV2 {
    width: 40%;

    select {
      margin-left: toRemDesktop(8);
      height: 100%;
    }
  }

  .Checkbox {
    height: toRemDesktop(20);
    margin-top: toRemDesktop(8);
    width: toRemDesktop(20);
  }

  .btn {
    padding: toRemDesktop(18) toRemDesktop(24);
    font-size: toRemDesktop(20);
    line-height: toRemDesktop(24);
    letter-spacing: toRemDesktop(1);
    border-radius: toRem(72);
  }

  .InputV2__root {
    margin-top: toRemDesktop(32);
    border-radius: toRemDesktop(20);

    label {
      font-size: toRem(14);
      line-height: toRem(16);
      letter-spacing: 1%;
    }

    input {
      border-radius: toRemDesktop(20);
      padding: toRemDesktop(14) toRemDesktop(20);
      margin-top: toRemDesktop(10);
      font-size: toRemDesktop(18);
      line-height: toRemDesktop(32);
    }
  }

  .error {
    font-size: toRemDesktop(12);
    margin-left: toRemDesktop(4);
  }
}

.Input__Field_Dark {
  margin-top: 0 !important;
  font-size: 0.75rem !important;
  font-style: normal;
  font-weight: 500;
  line-height: 1.3333333333rem;
  letter-spacing: 0.015rem;
  padding: 0.4166666667rem 0 0.4166666667rem 0.8333333333rem;
  border-radius: 10px;
  margin-top: 0.4166666667rem;
  width: 100%;
  border: 1px solid #e5e5e5 !important;
  height: 36px;
  background-color: transparent !important;
  color: #fff !important;
  transition: border-color 0.3s ease;

  &::placeholder {
    color: rgba(169, 169, 169, 0.7); // Placeholder color
    font-size: 0.70rem;
  }

  &:hover {
    border-color: #ccc; // Slightly darker border on hover
  }

  &:focus {
    outline: none;
    border-color: inherit !important; // Maintain the parent's border color
    background-color: inherit !important; // Use the background color of the parent div
  }

  &:disabled {
    background-color: #444; // Disabled background
    color: rgba(255, 255, 255, 0.5); // Dimmed text color
    border-color: #666; // Dimmed border
    cursor: not-allowed; // Not-allowed cursor
  }

  &.is-error {
    border-color: #ff4d4f; // Red border for error state
  }

  &.is-valid {
    border-color: #28a745; // Green border for valid state
  }

  &.is-readonly {
    background-color: #333; // Darker background for read-only
    color: rgba(255, 255, 255, 0.7); // Slightly dimmed text color
    border-color: #555; // Subtle border
    cursor: default; // Default cursor for read-only
  }
}
