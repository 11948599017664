@import '@/styles/_global';
.root {
  display: flex;
  justify-content: space-between;
}

.input {
  width: 22%;
  height: toRem(70);
  line-height: toRem(70);
  font-size: toRem(40);
  flex-shrink: 0;
  font-weight: 600;
  border-radius: toRem(20);
  border: 1px solid #686868;
  text-align: center;
  vertical-align: middle;
}

.input6 {
  width: calc(95% / 6);
  height: toRem(50);
  line-height: toRem(50);
  font-size: toRem(35);
  border-radius: toRem(10);
  border: 1px solid #686868;
  text-align: center;
  vertical-align: middle;

  &::-webkit-inner-spin-button{
    -webkit-appearance: none;
  }
}

.inputDark{
  background-color: #1a325d;
  color: #fff;
  border-color: #f5f5f5 !important;
}

@include desktop() {
  .root {
    gap: toRemDesktop(16);
  }
  .input {
    width: toRemDesktop(112);
    height: toRemDesktop(122);
    padding: toRemDesktop(25);
    line-height: toRemDesktop(122);
    font-size: toRemDesktop(64);
    flex-shrink: 0;
    font-weight: 600;
    border-radius: toRemDesktop(20);
    border: 1px solid #686868;
    text-align: center;
    vertical-align: middle;
  }

  .input6 {
    width: 3rem;
    height: 3rem;
    padding: toRemDesktop(20);
    line-height: toRemDesktop(102);
    font-size: 1.7rem;
    flex-shrink: 0;
    font-weight: 600;
    border-radius: toRemDesktop(20);
    border: 1px solid #686868;
    text-align: center;
    vertical-align: middle;
  }
}
