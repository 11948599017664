@import '@/styles/_global';
.group {
  label {
    display: block;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: toRem(14);
    font-weight: 600;
    letter-spacing: toRem(0.14);
    margin-bottom: toRem(8);
  }
  & + .group {
    margin-top: toRem(18);
  }
}
.control {
  display: flex;
  border-radius: toRem(8);
  border: 1px solid #e7e7e7;
  align-items: center;
  gap: toRem(10);
  height: toRem(42);
  padding: 0 toRem(12);
  overflow: hidden;
  & > svg,
  & > img {
    height: toRem(42);
    width: toRem(42);
    padding: toRem(18);
  }
}
.helper {
  margin-top: toRem(16);
  color: #686868;
  font-variant-numeric: lining-nums proportional-nums;
  font-size: toRem(12);
  font-weight: 600;
  letter-spacing: toRem(0.18);
  text-decoration-line: underline;
  margin-bottom: toRem(8);
}
.error {
  margin-top: toRemDesktop(8);
  color: #ff3e6c;
  font-size: 0.75rem;
}

@include desktop() {
  .group {
    label {
      font-size: toRemDesktop(16);
      letter-spacing: toRemDesktop(0.18);
      margin-bottom: toRemDesktop(10);
    }
    & + .group {
      margin-top: toRemDesktop(22);
    }
  }
  .control {
    display: flex;
    border-radius: toRemDesktop(20);
    border: 1px solid #e7e7e7;
    align-items: center;
    gap: toRemDesktop(10);
    height: toRemDesktop(60);
    padding: 0 toRemDesktop(10);
    overflow: hidden;
    & > svg,
    & > img {
      height: toRemDesktop(60);
      width: toRemDesktop(60);
      padding: toRemDesktop(18);
    }
  }
  .helper {
    margin-top: toRemDesktop(20);
    font-size: toRemDesktop(18);
    letter-spacing: toRemDesktop(0.18);
    margin-bottom: 0;
  }
  .error {
    margin-top: toRemDesktop(8);
    color: #ff3e6c;
    font-size: 0.75rem;
  }
}
