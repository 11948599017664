@import '@/styles/_global';
.root {
  &:disabled {
    opacity: 0.8;
    // cursor: wait;
  }
  display: inline-flex;
  padding: toRem(12) toRem(12);
  justify-content: center;
  align-items: center;
  // gap: toRem(12);
  border-radius: 999px;
  border: 1.5px solid #1a325d;
  background-color: #ffc907;
  color: #1a325d;
  span {
    margin-right: toRem(12);
    color: currentColor;
    text-align: center;
    font-family: var(--font-raleway);
    font-size: toRem(12);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: toRem(0.48);
    text-transform: uppercase;
  }
  svg {
    fill: currentColor;
  }
  img {
    width: toRem(17);
    height: auto;
    aspect-ratio: 17/14;
  }
  &.ciip {
    min-width: 8.8rem;
    font-weight: 800 !important;
  }
  &.w {
    color: #fff;
    border-color: #fff;
    background: #fff;
  }
  &.h {
    &:disabled {
      opacity: 1;
      background-color: rgba(231, 231, 231, 1); // Direct opacity applied
      color: rgb(120, 113, 108, 1);
      border-color: rgb(120, 113, 108);
      cursor: default; // No pointer effec
    }
    &:not(:disabled):hover {
      background: #ffc907;
      box-shadow: 0px 4px 24px 0px rgba(199, 199, 199, 0.4);
    }
  }
  &.g {
    background: linear-gradient(to top, #B38728, #FBF5B7);
    color: #1a325d;
    border:none;
  }
  &.y,
  &.h {
    color: #1a325d;
    border-color: #1a325d;
    background: #ffc907;

    &.y:hover {
      background: #ffc907;
      box-shadow: 0px 4px 24px 0px #07142c;
    }
    
    &.o {
      color: #ffc907;
    }
  }
  &.b {
    color: #fff;
    border-color: #1a325d;
    background: #1a325d;
    &.o {
      color: #1a325d;
    }
  }

  &.b:hover {
    &.o:hover {
      color: white;
      border-color: white;
      background-color: #1a325d !important;
      box-shadow: 0px 4px 24px 0px rgba(199, 199, 199, 0.8);
    }
  }

  &.w:hover {
    &.o:hover {
      color: #1a325d;
      border-color: white;
      background-color: white !important;
      box-shadow: 0px 4px 24px 0px #07142c;
    }
  }

  &.o {
    background: transparent;
  }
  &.bl {
    color: #fff;
    border-color: #1a325d;
    background: #1a325d;
    &.o {
      color: #1a325d;
    }
  }
}
@include desktop() {
  .root {
    height: toRemDesktop(60);
    padding: toRemDesktop(18) toRemDesktop(24);
    // gap: toRemDesktop(12);
    flex-shrink: 0;

    span {
      margin-right: toRemDesktop(12);
      font-size: toRemDesktop(20);
      letter-spacing: toRemDesktop(0.6);
      font-size: toRem(16);
    }
    img {
      max-width: toRem(17);
      max-height: toRem(17);
    }
    &.l {
      height: toRemDesktop(68);
      padding: toRemDesktop(20.4) toRemDesktop(27.2);
      span {
        font-size: toRemDesktop(22.667);
        letter-spacing: toRemDesktop(0.68);
      }
      img {
        max-width: toRem(20);
        max-height: toRem(20);
      }
    }
  }
}

