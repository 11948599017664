@import '@/styles/_global';
.root {
  position: fixed;
  inset: 0px;
  z-index: 10000;
  // Quick fix
  // max-height: 90vh;
  &::before {
    position: fixed;
    inset: 0;
    content: "";
    opacity: 0.4;
    background: #000000;
  }
  display: grid;
  place-items: center;
  overflow-y: auto; 
}
.close {
  height: toRem(24); 
  width: toRem(24); 
  position: absolute;
  right: toRem(10); 
  top: toRem(10); 
  z-index: 100;
  cursor: pointer;
}
.dialog {
  position: relative;
  width: toRem(351);
  padding:  toRem(9);
  border-radius: toRem(16);
  z-index: 1;
  margin-top: toRem(12);
  margin-bottom: toRem(12);
  // height: 100%;
  // max-height: 90vh;
}

.dialogNew{
  border-radius: toRem(24);
  position: relative;
}

.close path {
  fill: #1A325D;
}

.connectModalClose path {
  fill: #1A325D; 
}
.callUsModalClose path {
  fill: #885f48; 
}

.modalDialogParent {
  position: relative;
  width: 100%;
  height: 100%;
}


@include desktop() {
  .dialog {
    width: toRemDesktop(1269);
    padding: toRemDesktop(36) toRemDesktop(40);
    border-radius: toRemDesktop(32);
    z-index: 1;
    &.xl {
      width: toRemDesktop(1790);
      padding: toRemDesktop(90) toRemDesktop(120);
    }
  }
  .callUsModalClass{
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
}

@include mobile()
{
  .callUsModal{
    overflow: hidden;
  }
  .dialog{
    padding: toRem(9);
    margin: 0;
  }
  .callUsModalClass{
    position: absolute;
    transform: translate(-50%, -50%);
    top: calc(100% - 202px);
    left: 50%;
    width: 100%;
  }
  .connectModalClose path {
    fill: #879ec8; 
  }
  .callUsModalClose{
    fill: #885f48; 
  }
  .close{
    top: toRem(14); 
    right: toRem(14); 
  }
}

